export default interface Task {
  id: string | 'daily';
  title: string;
  url: string;
  chat_id: null;
  reward: string;
  type: string;
  min_level: string;
  icon: string;
  status: TaskStatus;
}

export enum TaskStatus {
  NOT_STARTED = 0,
  COMPLETED = 1,
  IN_PROGRESS = 2,
}