import claimDailyReward from 'api/claim-daily-reward'
import doneTask from 'api/done-task'
import React, { useContext, useState } from 'react'
import Task, { TaskStatus } from 'types/task'
import Icon, { IconType } from 'UI/Icon'
import appContext from 'utils/app-context'
import tg from 'utils/tg'

type Props = {
    task: Task
    active?:boolean
  handleClick: () => void;
}

const TaskCard = ({task,active,handleClick}: Props) => {
  const {icon,id,reward,title,url,status} = task
  const [pending, setpending] = useState(false)
  const {updateUserInfo} = useContext(appContext)

  const handleTask = async () => {
      setpending(true)
      if(id === 'daily') {
        await claimDailyReward()
        await handleClick()
        updateUserInfo()
        setpending(false)
        return
      }
      if(url) {
          try {
              tg.openTelegramLink(url);
            } catch (error) {
              tg.openLink(url);
            }
          }          
        setTimeout(async () => {
          await doneTask(id)
          await handleClick()
          updateUserInfo()
          setpending(false)
        }, 3000);
  }

  let statusIcon:IconType = 'arrowRight'

  switch (status) {
    case TaskStatus.COMPLETED:
        statusIcon = 'taskDone'
    break;

    case TaskStatus.IN_PROGRESS:
        statusIcon = 'loading'
    break;

    default:
        break;
  }

  if (pending) { statusIcon = 'loading' }

    return (
    <li className='task-card' data-active={active} onClick={handleTask}>
        <div className='task-card__left'>
            <img src={`img/task-icons/${id === 'daily' ? 'gift' : icon}.svg`} alt="" />
            <p>{title}</p>
        </div>
        <div className='task-card__right'>
            <img src="img/enp.png" alt="" />
            <p className='glow'>+ {reward}</p>
            <Icon icon={statusIcon}/>
        </div>
    </li>
  )
}

export default TaskCard