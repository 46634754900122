import { AnimatePresence, motion, Variants } from "framer-motion";
import { useRef } from "react";
import Button from "UI/Button";
import Icon from "UI/Icon";
import Select from "UI/Select";

type Props = {
  opened: boolean;
  close: () => void;
};

const options = [
  { id: 1, title: "Гостевые дома" },
  { id: 2, title: "Опция №2" },
  { id: 3, title: "Опция №3" },
  { id: 4, title: "Опция №4" },
  { id: 5, title: "Опция №5" },
];

const FiltersSettings = ({ opened, close }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <AnimatePresence>
      {opened && (
        <motion.div
          variants={variants}
          initial="enter"
          animate="animate"
          exit="enter"
          className="glass home-settings"
        >
          <Button onClick={close} className="home-settings-close">
            <Icon icon="close" />
          </Button>
          <div className="home-settings-row">
            <label htmlFor="categories">Категории</label>
            <Select options={options} />
          </div>
          <div className="home-settings-row">
            <label htmlFor="">Стоимость</label>
            <div className="home-settings-row-inputs">
              <input type="number" placeholder="От" className="glass" />
              <input type="number" placeholder="До" className="glass" />
            </div>
            <div className="rangeContainer glass" ref={ref}>
              <motion.div
                // drag="x"
                dragConstraints={ref}
                className="rangeContainerThumb gradient"
              />
            </div>
          </div>

          <div className="home-settings-row">
            <div className="home-settings-row-inputs">
              <div className="home-settings-option selected">
                Бассейн
                <img src="img/filter-categories-icons/pool.svg"/>
              </div>
              <div className="home-settings-option">
              С животными
                <img src="img/filter-categories-icons/animals.svg"/>
              </div>
              <div className="home-settings-option">
              Своя кухня
                <img src="img/filter-categories-icons/kitchen.svg"/>
              </div>
            </div>
            <div className="home-settings-row-inputs">
              <div className="home-settings-option">
              Парковка
                <img src="img/filter-categories-icons/parking.svg"/>
              </div>
              <div className="home-settings-option">
              Wi Fi
                <img src="img/filter-categories-icons/wifi.svg"/>
              </div>
              <div className="home-settings-option">
              Завтрак
                <img src="img/filter-categories-icons/breakfast.svg"/>
              </div>
            </div>
          </div>
          <div className="home-settings-row-inputs">
            <div className="home-settings-row">
              <label htmlFor="meters_center">До центра</label>
              <input type="number" placeholder="Метры" id="meters_center"/>
            </div>
            <div className="home-settings-row">
              <label htmlFor="meters_sea">До моря</label>
              <input type="number" placeholder="Метры" id="meters_sea"/>
            </div>
          </div>
          <div className="home-settings-row-inputs ">
            <Button className="home-settings-category active">
              Отели
            </Button>
            <Button className="home-settings-category">
            Рыбалка
            </Button>
            <Button className="home-settings-category">.home-settings-row input
            Охота
            </Button>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const variants: Variants = {
  enter: { opacity: 0, y: -50 },
  animate: { opacity: 1, y: 0 },
};

export default FiltersSettings;
