import React, { useEffect, useState } from "react";
import PageWrapper from "UI/PageWrapper";
import UserTopBar from "UI/UserTopBar";
import "./Earn.css";
import Task from "types/task";
import getTasks, { Everyday } from "api/get-tasks";
import TaskCard from "./components/TaskCard";

const Earn: React.FC = () => {
  const [tasks, settasks] = useState<Task[] | null>(null);
  const [daily, setdaily] = useState<Everyday | null>(null);

  const fetchTasks = async () => {
    const res = await getTasks();
    if (res) {
      settasks(res.tasks);
        setdaily(res.everyday);
    }
  };
  useEffect(() => {
    fetchTasks();
  }, []);

  const dailyTask: Task = {
    id: "daily",
    title: "Ежедневный бонус",
    icon: "img/subscribe.png",
    url: "https://youtube.com",
    chat_id: null,
    min_level: "0",
    reward: String(daily ? daily?.stages[daily.current_stage] : 0),
    status: daily?.available ? 0 : 1,
    type: "daily",
  };

  return (
    <PageWrapper>
      <UserTopBar />
      <div className="earn-content">
        <div className="earn-content__tasks">
          <h2>Ежедневные задания</h2>
          <ul className="tasks-list">
            {daily && (
              <TaskCard task={dailyTask} active handleClick={fetchTasks} />
            )}
          </ul>
        </div>
      </div>
      <div className="earn-content">
        <div className="earn-content__tasks">
          <h2>Все задания</h2>
          <ul className="tasks-list">
            {tasks &&
              tasks.map((task) => (
                <TaskCard task={task} handleClick={fetchTasks} />
              ))}
          </ul>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Earn;
