import React, { useContext, useEffect } from 'react'
import users from 'assets/images/icons/users.svg'
import stars from 'assets/images/icons/stars.svg'
import Icon from 'UI/Icon'
import appContext from 'utils/app-context'
import Button from 'UI/Button'
import tg from 'utils/tg'
import formatNumber from 'utils/format-number'

const tg_id = tg.initDataUnsafe.user?.id || process.env.REACT_APP_TEST_ID;
const inviteLink = process.env.REACT_APP_SHARE_LINK?.replace('TG_ID',String(tg_id)) || process.env.REACT_APP_SHARE_LINK || ''
const shareText = process.env.REACT_APP_SHARE_TEXT || '';


const FriendsReferals = () => {
  const {user} = useContext(appContext)

  const copyShareLink = (e:React.MouseEvent) => {
    tg.HapticFeedback.notificationOccurred('success')
    navigator.clipboard.writeText(inviteLink)
  }
  const TelegramShare = () => {
      tg.openTelegramLink(`https://t.me/share/url?url=${inviteLink}&text=${(shareText.replace(/\\n/g, '%0A'))}`)
  }


  return (
    <div className='friends-lines'>
      <p style={{fontSize:12}}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's.
      </p>
      <div className="fr-info-blocks">
            <div className="fr-info-block glass">
              <Icon icon="info"/>
              <img src={users}/>
              <p>5% с оплат приглашенных друзей</p>
            </div>
            <div className="fr-info-block glass">
              <Icon icon="info"/>
              <img src={stars}/>
              <p>0.1 TON за приглашение организаторов</p>
            </div>
      </div>
      <p className='friends-counter'>Друзья ({user?.friends}):</p>
      <ul className='friends-list'>
        {user?.friends_list.map(({coins,name,photo}) => (
          <li className='glass'>
          <div>
            <img src={photo} alt="" />
            <p>{name}</p>
          </div>
          <div className='fr-reward'>
            <Icon icon='ton'/>
            <p className='glow'>+ {formatNumber(coins)}</p>
          </div>
        </li>
        ))}
      </ul>

      <div className='invite-block glass'>
        <Button onClick={copyShareLink} className='copy-link'>
          <div>Ссылка <Icon icon='copy'/></div>
        </Button>
        <Button onClick={TelegramShare}>Пригласить друга</Button>
      </div>
    </div>
  )
}

export default FriendsReferals