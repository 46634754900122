export default interface UserData {
  balance: number;
  first_name: string;
  last_name: string;
  username: string;
  avatar: string;
  farming_status: FarmingStatus;
  farming_current: number;
  farming_total: number;
  farming_seconds: number;
  farming_period: number;
  everyday_task: Everydaytask;
  friends: number;
  error?:string
  friends_list: Friendslist[];
}

export enum FarmingStatus {
  NOT_STARTED = 0,
  ACTIVE = 1,
  FINISHED = 2,
}

interface Friendslist {
  name: string;
  coins: number;
  photo: string;
}

interface Everydaytask {
  stage: number;
  stages: number[];
  reward: null;
}