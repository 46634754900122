import React, { useState } from "react";
import "./Home.css";
import PageWrapper from "UI/PageWrapper";
import UserTopBar from "UI/UserTopBar";
import Button from "UI/Button";
import Icon from "UI/Icon";
import testImage from "assets/services/1.jpg";
import { AnimatePresence, motion, Variants } from "framer-motion";
import FiltersSettings from "./components/FiltersSettings";
type Props = {};

const Home = (props: Props) => {
  const [[page, direction], setPage] = useState([0, 0]);
  const current = services[page];
  const [openedSettings, setOpenedSettings] = useState(true)

  const paginate = (newDirection: number) => {
    setPage([page + 1, newDirection]);
  };

  const swipeConfidenceThreshold = 20000;
  const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
  };

  return (
    <PageWrapper className="home-page">
      <UserTopBar />

      <AnimatePresence mode="sync" custom={direction}>
        {current && (
          <motion.img
            src={current.image}
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
            }}
            className="service-image"
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);

              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
            }}
          ></motion.img>
        )}
      </AnimatePresence>
      <div style={{ margin: 8, display: "flex", justifyContent: "end" }}>
        <Button className=" glass btn-sl">
          <Icon icon="settings" />
        </Button>
      </div>
      <Button 
      onClick={() => paginate(1)}
      className="select-service-btn">
        <Icon icon="arrowRight" />
        <Icon icon="dislike" />
      </Button>
      <Button 
      onClick={() => paginate(-1)}
      className="select-service-btn right">
        <Icon icon="arrowRight" />
        <Icon icon="like" />
      </Button>
      <div className="home-bottom">
        <div className="service-info">
          {current && <p>{current.title}</p>}
          {current && <div>
            <span>{current.rate}</span>
            <Icon icon="gradientStar" />
          </div>}
        </div>
        <div className="home-actions">
          <Button onClick={() => setOpenedSettings(true)} className=" glass btn-sl">
            <Icon icon="tuners" />
          </Button>
          <Button className=" glass btn-sl">
            <Icon icon="heart" />
          </Button>
        </div>
      </div>
      <FiltersSettings opened={openedSettings} close={() => setOpenedSettings(false)}/>
    </PageWrapper>
  );
};

const services = [
  { title: "OOO «Мир Развлечений»", rate: 3.1, image: testImage },
  { title: "OOO «Планета Детства»", rate: 4.2, image: testImage },
  { title: "OOO «Технологии Будущего»", rate: 2.8, image: testImage },
  { title: "OOO «Спорт и Отдых»", rate: 3.7, image: testImage },
  { title: "OOO «Кулинарное Искусство»", rate: 4.5, image: testImage },
  { title: "OOO «Зеленый Мир»", rate: 4.0, image: testImage },
  { title: "OOO «Культурный Центр»", rate: 3.9, image: testImage },
  { title: "OOO «Мир Творчества»", rate: 3.6, image: testImage },
  { title: "OOO «Эко-Туризм»", rate: 4.8, image: testImage },
  { title: "OOO «Цифровой Мир»", rate: 2.5, image: testImage },
];

const variants:Variants = {
  enter: {
    zIndex: 1,
    x: 0,
    opacity: 0,
    scale: 0.5,
    borderRadius:50,
    
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
    scale: 1,
    borderRadius:0,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};
export default Home;
