import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

type Option = {
  id: number;
  title: string;
};

type Props = {
  options: Option[];
};

const Select: React.FC<Props> = ({ options }) => {
  const [selected, setSelected] = useState<number | null>(null);
  const [opened, setOpened] = useState(false);

  const selectedOption = options.find(option => option.id === selected);

  return (
    <div className="custom-select glass" style={{ position: 'relative'}}>
      {/* Selected Option */}
      <div
        className="selected"
        onClick={() => setOpened(prev => !prev)}
        style={{
          cursor: 'pointer',
          userSelect: 'none',
        }}
      >
        {selectedOption ? selectedOption.title : 'Выберите опцию'}
      </div>

      {/* Dropdown Options */}
      <AnimatePresence>
        {opened && (
          <motion.ul
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className='custom-select-list glass'
          >
            {options.map(option => (
              <motion.li
                key={option.id}
                whileHover={{ scale: 1.05, backgroundColor: '#f0f0f0' }}
                whileTap={{ scale: 0.95 }}
                onClick={() => {
                  setSelected(option.id);
                  setOpened(false);
                }}
                style={{
                  padding: '10px 15px',
                  cursor: 'pointer',
                  borderBottom: '1px solid #eee',
                  userSelect: 'none',
                }}
              >
                {option.title}
              </motion.li>
            ))}
          </motion.ul>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Select;
