import React, { useContext } from "react";
import PageWrapper from "UI/PageWrapper";
import UserTopBar from "UI/UserTopBar";
import appContext from "utils/app-context";
import formatNumber from "utils/format-number";
import './Mining.css'
import Button from "UI/Button";
import Icon from "UI/Icon";
import img from 'assets/images/mining-image.png'
import useFarming from "hooks/use-farming";
import claimFarming from "api/claim-farming";
import startFarming from "api/start-farming";
import { FarmingStatus } from "types/user-data";
import tg from "utils/tg";

type Props = {};

const Mining = (props: Props) => {
  const { user,updateUserInfo,farmingService} = useContext(appContext);
    const {coins,percent,seconds} = farmingService
   
    const handleFarmingButton = async () => {
      switch (user?.farming_status) {
        case FarmingStatus.NOT_STARTED:
          await startFarming();
          tg.HapticFeedback.impactOccurred('medium')
          break;
  
        case FarmingStatus.FINISHED:
          await claimFarming();
          tg.HapticFeedback.notificationOccurred('success')
          break;
  
        default:
          break;
      }
      updateUserInfo();
    };
    if (!user) return null;
    const isFarmingActive = user?.farming_status === FarmingStatus.ACTIVE;
    const isFarmingNotStarted = user?.farming_status === FarmingStatus.NOT_STARTED;
    const isFarmingFinished = user?.farming_status === FarmingStatus.FINISHED;
  return (
    <PageWrapper>
      <div className="mining-page">
      <UserTopBar />
      <div className="mining-content">

      <div className="mining-balance">
        <img src="img/enp.png" alt="" />
        <p className="glow">{formatNumber(user?.balance)}</p>
      </div>
      <div className="mining-stats">
        <p className="glow">11 T/h</p>
        <p className="glow">4 H</p>
      </div>
      <div className="mining-buttons">
        <Button>
            Boost Power
        </Button>
        <Button>
            <Icon icon="lock"/>
            Boost Time
        </Button>
      </div>
        <img className="mining-img" src={img} alt="" />
        <p className="mining-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
      </div>
      <Button className="mining-button" onClick={handleFarmingButton}>
        {isFarmingNotStarted && 'Start Mining'}
      {isFarmingActive && <>
        <div className="mining-progress">
          <span>{percent.toFixed(2)}%</span>
          <span>{coins.toFixed(4)}T</span>
          <span>{formatSeconds(seconds)}</span>
        </div>
        <div className="mining-progress--overlay gradient"
        style={{ width: `${percent}%` }}>
          <div className="mining-progress">
            <span>{percent.toFixed(2)}%</span>
            <span>{coins.toFixed(4)}T</span>
            <span>{formatSeconds(seconds)}</span>
          </div>
        </div>
      </>}
      {isFarmingFinished &&
        <div className="mining-progress--overlay gradient"
        style={{ width: `${100}%` }}>
          <div className="mining-progress" style={{justifyContent: 'center'}}>
            <span> Claim </span>
          </div>
        </div>
      }
      </Button>
      </div>
    </PageWrapper>
  );
};

// params - seconds, return formatted to 0:59
const formatSeconds = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);  
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
}

export default Mining;
